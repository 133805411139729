import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import EdgesensorHighRoundedIcon from "@mui/icons-material/EdgesensorHighRounded";
import ViewQuiltRoundedIcon from "@mui/icons-material/ViewQuiltRounded";
import HubIcon from "@mui/icons-material/Hub";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import BalanceIcon from "@mui/icons-material/Balance";
import { alpha } from "@mui/material";
import { Dashboard } from "@mui/icons-material";

const items = [
  {
    icon: <BlurOnIcon />,
    title: "Extensive Dataset Coverage",
    description:
      "With over 19,860 combinations of datapoints available for filtering, users have access to a comprehensive and diverse dataset. This vast amount of data allows users to explore various dimensions of the real estate market, including number of bedrooms, postcodes and property types spanning across sales and rental market. The abundance of data ensures that users can conduct in-depth analysis and make well-informed decisions based on a rich pool of information.",
    imageLight: "PPTScreen",
    imageDark: "PPTSlide",
  },
  {
    icon: <SlideshowIcon />,
    title: "Presentation Analytics",
    description:
      "Generate branded professional-looking PowerPoint presentations directly from the dashboard. Users can select desired data points, charts, and insights from the dashboard and automatically generate a presentation slide deck. This feature streamlines the process of creating client presentations, saving time and effort. It enables users to present their findings and recommendations to clients in a visually appealing and impactful manner.",
    imageLight: "PPTScreen",
    imageDark: "",
  },
  {
    icon: <BalanceIcon />,
    title: "Competitor Benchmarking",
    description:
      "Conduct comprehensive competitor analysis with our benchmarking tool. Easily identify your position within the market landscape by comparing key metrics with specific competitors and top-performing agents. Gain valuable insights into market trends, pricing strategies, and performance metrics to inform strategic decision-making. With customizable comparison parameters and detailed reports, stay ahead of the competition and maximize your competitive advantage in the real estate industry.",
    imageLight:
      'url("/static/images/templates/templates-images/devices-light.png")',
    imageDark:
      'url("/static/images/templates/templates-images/devices-dark.png")',
  },
];

const imageList = [
  SingleView("https://d1nie1m83b14nz.cloudfront.net/dataset2.png"),
  PresentationView(),
  SingleView("https://d1nie1m83b14nz.cloudfront.net/competitor.png"),
];

function SingleView(image) {
  return (
    <img
      src={image}
      alt="Dashboard"
      style={{
        width: "100%",
        objectFit: "contain",
        borderRadius: "2px",
        boxShadow: `0 0 24px 12px ${alpha("#033363", 0.2)}`,
        outline: "1px solid",
        outlineColor: alpha("#9CCCFC", 0.1),
      }}
    />
  );
}

function PresentationView() {
  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <img
        src={"https://d1nie1m83b14nz.cloudfront.net/ppt_icon2.png"}
        alt="Dashboard"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          objectFit: "contain",
          borderRadius: "2px",
          boxShadow: `0 0 24px 12px ${alpha("#033363", 0.2)}`,
          outline: "1px solid",
          outlineColor: alpha("#9CCCFC", 0.1),
        }}
      />
      <img
        src={"https://d1nie1m83b14nz.cloudfront.net/ppt_image%202.png"}
        alt="Dashboard"
        style={{
          position: "absolute",
          top: 190,
          left: 30,
          width: "100%",
          objectFit: "contain",
          borderRadius: "2px",
          boxShadow: `0 0 24px 12px ${alpha("#033363", 0.2)}`,
          outline: "1px solid",
          outlineColor: alpha("#9CCCFC", 0.1),
        }}
      />
    </div>
  );
}

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              Product features
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              Explore our suite of powerful tools designed to provide
              unparalleled insights into the real estate market, offering
              extensive dataset coverage, comprehensive presentation analytics,
              and advanced competitor benchmarking capabilities.
            </Typography>
          </div>
          <Grid
            container
            item
            gap={1}
            sx={{ display: { xs: "auto", sm: "none" } }}
          >
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index ? "primary.light" : "";
                    }
                    return selectedItemIndex === index ? "primary.light" : "";
                  },
                  background: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index ? "none" : "";
                    }
                    return selectedItemIndex === index ? "none" : "";
                  },
                  backgroundColor:
                    selectedItemIndex === index ? "primary.main" : "",
                  "& .MuiChip-label": {
                    color: selectedItemIndex === index ? "#fff" : "",
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: "auto", sm: "none" },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === "light"
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: 280,
              }}
            >
              {imageList[selectedItemIndex]}
            </Box>
            <Box sx={{ px: 2, pb: 2, paddingTop:selectedItemIndex==1? "37%":"2%" }}>
              <Typography
                color="text.primary"
                variant="body2"
                fontWeight="bold"
              >
                {selectedFeature.title}
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
                sx={{ my: 0.5 }}
              >
                {selectedFeature.description}
              </Typography>
              <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  "& > svg": { transition: "0.2s" },
                  "&:hover > svg": { transform: "translateX(2px)" },
                }}
              >
                <span>Learn more</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: "1px", ml: "2px" }}
                />
              </Link>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: "100%", display: { xs: "none", sm: "flex" } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: "fit-content",
                  width: "100%",
                  background: "none",
                  backgroundColor:
                    selectedItemIndex === index ? "action.selected" : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index
                        ? "primary.light"
                        : "grey.200";
                    }
                    return selectedItemIndex === index
                      ? "primary.dark"
                      : "grey.800";
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    textAlign: "left",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { md: "center" },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === "light") {
                          return selectedItemIndex === index
                            ? "primary.main"
                            : "grey.300";
                        }
                        return selectedItemIndex === index
                          ? "primary.main"
                          : "grey.700";
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: "none" }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                    <Link
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        "& > svg": { transition: "0.2s" },
                        "&:hover > svg": { transform: "translateX(2px)" },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: "1px", ml: "2px" }}
                      />
                    </Link>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: "none", sm: "flex" }, width: "100%" }}
        >
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              width: "100%",
              display: { xs: "none", sm: "flex" },
              pointerEvents: "none",
            }}
          >
            <Box
              sx={{
                m: "auto",
                width: 420,
                height: 500,
                backgroundSize: "contain",
                backgroundImage: (theme) =>
                  theme.palette.mode === "light"
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
              }}
            >
              {imageList[selectedItemIndex]}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
