import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AutoFixHighRoundedIcon from "@mui/icons-material/AutoFixHighRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TimelineIcon from "@mui/icons-material/Timeline";
import Group from "@mui/icons-material/Group";

const items = [
  {
    icon: <AssessmentIcon />,
    title: "Comprehensive Data Insights",
    description:
      "Unlock 2-year real estate trends for confident, informed decisions.",
  },
  {
    icon: <TouchAppIcon />,
    title: "Interactive Experience",
    description:
      "Dive deep, tailor analysis effortlessly, save time, uncover valuable insights.",
  },
  {
    icon: <TrendingUpIcon />,
    title: "Market Overview",
    description:
      "Stay ahead, grasp market dynamics, spot opportunities, navigate shifts confidently.",
  },
  {
    icon: <TimelineIcon />,
    title: "Competitor Analysis",
    description:
      "Gain competitive edge, analyze rivals, identify growth opportunities, refine strategies.",
  },
  {
    icon: <Group />,
    title: "User-Friendly Interface",
    description:
      "Navigate seamlessly, visualize data beautifully, access insights effortlessly, all levels.",
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: "Actionable Insights",
    description:
      "Turn data into action, optimize strategies, capitalize on trends, achieve goals.",
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "#06090a",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4">
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            Access the potential of our real estate dashboard: comprehensive
            insights, interactive experience, and actionable strategies.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: "100%",
                  border: "1px solid",
                  borderColor: "grey.800",
                  background: "transparent",
                  backgroundColor: "grey.900",
                }}
              >
                <Box sx={{ opacity: "50%" }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.400" }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
