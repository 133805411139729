import * as React from "react";
import PropTypes from "prop-types";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppAppBar from "./components/AppAppBar";
import Hero from "./components/Hero";
import Highlights from "./components/Highlights";
import Features from "./components/Features";
import Footer from "./components/Footer";
import getLPTheme from "./getLPTheme";
import BasicLayout from "./components/BasicLayout";


export default function LandingPage() {
  const [mode, setMode] = React.useState("dark");
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };



  return (
    <BasicLayout>
      <ThemeProvider theme={LPtheme }>
        <CssBaseline />
        <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
        <Hero />
        <Box sx={{ bgcolor: "background.default" }}>
          <Features />
          <Divider />
          <Highlights />
          <Divider />
          {/* <Pricing />
          <Divider /> */}
          <Footer />
        </Box>

      </ThemeProvider>
    </BasicLayout>
  );
}
