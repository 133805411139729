import "./App.css";
import LandingPage from "./layouts/landing-page/LandingPage";
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import CssBaseline from "@mui/material/CssBaseline";

function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LandingPage />
    </ThemeProvider>
  );
}

export default App;
