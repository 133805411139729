import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Hover from "react-3d-hover";

export default function Hero() {
  return (
    <Box
      id="overview"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(180deg, #CEE5FD, #FFF)"
            : `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: "100%", sm: "70%" } }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: "column",
              flexDirection: { xs: "column", md: "row" },
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            Unlock&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light" ? "#FFFF" : "#FFFF",
              }}
            >
              <span>R</span>
              <span style={{ color: "#0866C2" }}>eal&nbsp;</span>
            </Typography>
            <Typography
              component="span"
              variant="h1"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light" ? "#FFFF" : "#FFFF",
              }}
            >
              <span>D</span>
              <span style={{ color: "#0866C2" }}>ata&nbsp;</span>
            </Typography>
            <Typography
              component="span"
              variant="h1"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light" ? "#FFFF" : "#FFFF",
              }}
            >
              <span>I</span>
              <span style={{ color: "#0866C2" }}>nsights</span>
            </Typography>
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary">
            Punching through the clutter of real estate data, our web
            application offers insightful analysis, guiding you through the
            complexities of the market.
            <br /> <br /> Welcome to a place where data speaks volumes, guiding
            your journey towards successful real estate transactions.
          </Typography>
        </Stack>
        <Hover scale={1.1} perspective={1800} speed={1000}>
          <Box
            id="image"
            sx={(theme) => ({
              mt: { xs: 8, sm: 10 },
              alignSelf: "center",
              height: { xs: 200, sm: 800 },
              backgroundImage:
                theme.palette.mode === "light"
                  ? "DashboardImage"
                  : "DashboardImage",
              backgroundSize: "cover",
              borderRadius: "10px",
              outline: "1px solid",
              outlineColor:
                theme.palette.mode === "light"
                  ? alpha("#BFCCD9", 0.5)
                  : alpha("#9CCCFC", 0.1),
              mt: { xs: 8, sm: 10 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                  : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
            })}
          >
            <img
              src={
                "https://d1nie1m83b14nz.cloudfront.net/DashboardScreenshot.png"
              }
              alt="Dashboard"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: "10px",
                boxShadow: `0 0 24px 12px ${alpha("#033363", 0.2)}`,
                outline: "1px solid",
                outlineColor: alpha("#9CCCFC", 0.1),
              }}
            />
          </Box>
        </Hover>
      </Container>
    </Box>
  );
}
